import React, { useState, useEffect } from 'react'
import { auth } from '../firebase';

function Menu() {
    const [user, setUser] = useState();
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUser(user);
        });
    });
    return (
        <div>
            {/* Main Sidebar Container */}
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <a href="#0" className="brand-link">
                    <img src="dist/img/AdminLTELogo.png" alt="A" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                    <span className="brand-text font-weight-light">RESTO </span>
                </a>
                <div className="sidebar">
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <li className="nav-item">
                                <a href="/home" className="nav-link">
                                    <i className="nav-icon fa fa-home" />
                                    <p>Home</p>
                                </a>
                            </li>
                            <li className="nav-header">Orders</li>
                            <li className="nav-item">
                                <a href="/table-orders" className="nav-link">
                                    <i className="nav-icon fa fa-eye" />
                                    <p>Table Orders</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/view-orders" className="nav-link">
                                    <i className="nav-icon fa fa-glass" />
                                    <p>View Orders</p>
                                </a>
                            </li>
                            <li className="nav-header">Purchase</li>
                            <li className="nav-item">
                                <a href="/add-purchase" className="nav-link">
                                    <i className="nav-icon fa fa-pencil" />
                                    <p>Add Purchase</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/view-purchase" className="nav-link">
                                    <i className="nav-icon fa fa-book" />
                                    <p>View Purchase</p>
                                </a>
                            </li>
                            <li className="nav-header">Customers</li>
                            <li className="nav-item">
                                <a href="/customers" className="nav-link">
                                    <i className="nav-icon far fa-user" />
                                    <p>Customers</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/customer-account" className="nav-link">
                                    <i className="nav-icon far fa-credit-card " />
                                    <p>Customer Account</p>
                                </a>
                            </li>
                            <li className="nav-header">Dealers</li>
                            <li className="nav-item">
                                <a href="/dealer-details" className="nav-link">
                                    <i className="nav-icon far fa-user-circle" />
                                    <p>Dealer Details</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/dealer-account" className="nav-link">
                                    <i className="nav-icon  far fa-credit-card " />
                                    <p>Dealer Account</p>
                                </a>
                            </li>
                            <li className="nav-header">Products</li>
                            <li className="nav-item">
                                <a href="/product-details" className="nav-link">
                                    <i className="nav-icon far fa-calendar-alt" />
                                    <p>Product Details</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/product-category" className="nav-link">
                                    <i className="nav-icon far fa-calendar-check-o " />
                                    <p>Product Category</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/product-unit" className="nav-link">
                                    <i className="nav-icon far fa-calendar-check-o " />
                                    <p>Product Unit</p>
                                </a>
                            </li>
                            <li className="nav-header">Seating Arrangements</li>
                            <li className="nav-item">
                                <a href="/seating-details" className="nav-link">
                                    <i className="nav-icon fa fa-table" />
                                    <p>Seating Details</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/seating-category" className="nav-link">
                                    <i className="nav-icon fa fa-table" />
                                    <p>Seating Category</p>
                                </a>
                            </li>
                            <li className="nav-header">Staff Mgmt</li>
                            <li className="nav-item">
                                <a href="/staff-details" className="nav-link">
                                    <i className="nav-icon fa fa-users" />
                                    <p>Staff Details</p>
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a href="/staff-advance" className="nav-link">
                                    <i className="nav-icon fa fa-file" />
                                    <p>Staff Advance</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/staff-payment" className="nav-link">
                                    <i className="nav-icon fa fa-money" />
                                    <p>Staff Payment</p>
                                </a>
                            </li> */}
                            <li className="nav-header">Report</li>
                            <li className="nav-item">
                                <a href="/customer-outstanding" className="nav-link">
                                    <i className="nav-icon far fa-file-alt" />
                                    <p>Customer Outstanding</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/excise-report" className="nav-link">
                                    <i className="nav-icon far fa-file-alt" />
                                    <p>Stock Report</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/sale-purchase-report" className="nav-link">
                                    <i className="nav-icon far fa-file-alt" />
                                    <p>Sale/Purchase</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/customerwise-report" className="nav-link">
                                    <i className="nav-icon far fa-file-alt" />
                                    <p>Customer Wise Sale </p>
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a href="/productwise-sale-report" className="nav-link">
                                    <i className="nav-icon far fa-file-alt" />
                                    <p>Product Wise Sale </p>
                                </a>
                            </li> */}
                            {/* <li className="nav-item">
                                <a href="/staff-payment-report" className="nav-link">
                                    <i className="nav-icon far fa-file-alt" />
                                    <p>Staff Payment </p>
                                </a>
                            </li> */}
                            {/* <li className="nav-item">
                                <a href="/dealerwise-report" className="nav-link">
                                    <i className="nav-icon far fa-file-alt" />
                                    <p>Dealer Wise Purchase </p>
                                </a>
                            </li> */}
                        </ul>
                    </nav>
                    <br/><br/><br/>
                    {/* /.sidebar-menu */}
                </div>
                {/* /.sidebar */}
            </aside>


        </div>
    )
}
export default Menu