import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, limit, getCountFromServer, updateDoc, writeBatch, getAggregateFromServer, sum } from 'firebase/firestore'
import Select from 'react-select'
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

function TableOrders() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  const [userData, setUserData] = useState();
  const [seatingCat, setseatingCat] = useState([]);
  const [seatings, setseatings] = useState([]);
  const [productCat, setproductCat] = useState([]);
  const [products, setproducts] = useState([]);
  const [orderitems, setorderitems] = useState([]);
  const [customers, setcustomers] = useState([]);
  const [staffs, setstaffs] = useState([]);

  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");

  const [orderDocid, setorderDocid] = useState("");
  const [tableid, settableid] = useState("");
  const [tablename, settablename] = useState("");
  const [orderdate, setorderdate] = useState("");
  const [custid, setcustid] = useState("");
  const [customer, setcustomer] = useState("");
  const [staffid, setstaffid] = useState("");
  const [staff, setstaff] = useState("");
  const [billno, setbillno] = useState("");
  const [tablebillno, settablebillno] = useState("");
  const [totalamount, settotalamount] = useState(0);
  const [discount, setdiscount] = useState(0);
  const [paid, setpaid] = useState(0);
  const [remaining, setremaining] = useState("");
  const [paytype, setpaytype] = useState("");

  const [revrem, setrevrem] = useState(0);


  const [item, setitem] = useState("");
  const [itemid, setitemid] = useState("");

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUserData(user);
    });
  });


  useEffect(() => {
    finaltotal()
    fetchData1();
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setorderdate(formattedDate);
  }, []);


  const fetchData1 = async () => {
    try {
      const q = query(collection(db, 'seatingcats'), orderBy("created", "asc"))
      onSnapshot(q, (querySnapshot) => {
        setseatingCat(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));
      });

      const q1 = query(collection(db, 'seatings'), orderBy("created", "asc"))
      onSnapshot(q1, async (querySnapshot1) => {
        const combinedData = [];
        let orders = [];
        let status = "";
        for (const doc1 of querySnapshot1.docs) {
          const q5 = query(collection(db, "orders"), where("tableid", "==", doc1.id), where("status", "==", 'Booked'), orderBy("created", "asc"));
          const querySnapshot2 = await getDocs(q5);
          if (querySnapshot2.empty) {
            status = "Available";
            orders = [];
          } else {
            querySnapshot2.forEach((doc) => {
              orders.push({
                id: doc.id,
                data: doc.data(),
              })
            }
            );
            status = "Booked";
          }

          combinedData.push({
            id: doc1.id,
            data: doc1.data(),
            status: status,
            orders: orders,
          });

          orders = [];
        }
        setseatings(combinedData);
      });

      const q2 = query(collection(db, 'productcats'))
      onSnapshot(q2, (querySnapshot) => {
        setproductCat(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));
      });

      const q3 = query(collection(db, 'products'), orderBy("prodname", "desc"))
      onSnapshot(q3, async (querySnapshot1) => {

        const combinedData1 = [];
        let catname = "";
        let unit = "";
        for (const doc1 of querySnapshot1.docs) {
          if (doc1.data().unit !== "") {
            const docRef1 = doc(db, "productunits", doc1.data().unit);
            const docSnap1 = await getDoc(docRef1);
            unit = docSnap1.data().unit;
          } else {
            unit = "";
          }

          combinedData1.push({
            id: doc1.id,
            data: doc1.data(),
            catname: catname,
            unit: unit,
            label: doc1.data().prodname + " " + unit,
            value: doc1.id,
          });
        }
        setproducts(combinedData1);
      });

      const q6 = query(collection(db, 'customers'), orderBy('created', "desc"))
      onSnapshot(q6, (querySnapshot) => {
        setcustomers(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data(),
          label: doc.data().name,
          value: doc.id,
        })));
      });

      const q7 = query(collection(db, 'staffs'), orderBy('created', "desc"))
      onSnapshot(q7, (querySnapshot) => {
        setstaffs(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data(),
          label: doc.data().name,
          value: doc.id,
        })));
      });

    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message
      });
    }
  }

  const fetchProducts = (prodcatid) => {
    const q3 = query(collection(db, 'products'), where("prodcatid", "==", prodcatid))
    onSnapshot(q3, async (querySnapshot1) => {

      const combinedData = [];
      let catname = "";
      let unit = "";
      for (const doc1 of querySnapshot1.docs) {
        if (doc1.data().prodcatid) {
          const docRef = doc(db, "productcats", doc1.data().prodcatid);
          const docSnap = await getDoc(docRef);
          catname = docSnap.data().catname;
        }
        else {
          catname = "";
        }

        if (doc1.data().unit !== "") {
          const docRef1 = doc(db, "productunits", doc1.data().unit);
          const docSnap1 = await getDoc(docRef1);
          unit = docSnap1.data().unit;
        } else {
          unit = "";
        }

        combinedData.push({
          id: doc1.id,
          data: doc1.data(),
          catname: catname,
          unit: unit,
          label: catname + " - " + doc1.data().prodname + " " + unit,
          value: doc1.id,
        });
      }
      setproducts(combinedData);
    });
  }

  const handleSelectItem = (item) => {
    setitem("");
    setitemid(item.id);
    addOrderItems(item, item.id, item.data.rate)
  };

  const handleSelectItem1 = async (item) => {
    setcustomer(item);
    setcustid(item.id);
    if (orderDocid) {
      await updateDoc(doc(db, 'orders', orderDocid), {
        custid: custid,
        customer: customer,
      });
    }

    const q1 = query(collection(db, 'customeracchistory'), where("custid", "==", item.id));
    const snapshot = await getAggregateFromServer(q1, {
      paid: sum('paid')
    });

    const q2 = query(collection(db, 'orders'), where("custid", "==", item.id));
    const snapshot1 = await getAggregateFromServer(q2, {
      totalamount: sum('totalamount')
    });

    const g3 = query(collection(db, 'customeracchistory'), where("custid", "==", item.id));
    const snapshot2 = await getAggregateFromServer(g3, {
      discount: sum('discount')
    });

    let remaining = parseFloat(snapshot1.data().totalamount) - parseFloat(snapshot2.data().discount) - parseFloat(snapshot.data().paid);
    setrevrem(remaining);


  };

  const handleSelectItem2 = async (item) => {
    setstaff(item);
    setstaffid(item.id);
  }

  const addTableId = async (tableid, tablename) => {
    setrevrem(0);
    settableid(tableid); settablename(tablename);
    setorderDocid("");
    setorderitems([]);
    const coll = collection(db, "orders");
    const snapshot = await getCountFromServer(coll, where("orderdate", "==", orderdate));
    let billno = parseFloat(snapshot.data().count) + 1;
    setbillno(billno);

    setcustid(snapshot.data().custid);
    setcustomer("");
    setstaff("");
    setstaffid(snapshot.data().staffid);

    const coll1 = collection(db, "orders");
    const snapshot1 = await getCountFromServer(coll1, where("tableid", "==", tableid), where("orderdate", "==", orderdate));
    let tablebillno = parseFloat(snapshot1.data().count) + 1;
    settablebillno(tablebillno);
  }

  const addTableId1 = async (tableid, tablename, tableno, billno1, orderid, custid, customer, staffid, staff) => {
    fetchOrderItems(tableid, tableno, orderid);
    settablebillno(tableno);
    settableid(tableid);
    settablename(tablename);
    setorderDocid(orderid);
    setbillno(billno1);

    setcustid(custid);
    setcustomer(customer);

    setstaff(staff);
    setstaffid(staffid);

    const q1 = query(collection(db, 'customeracchistory'), where("custid", "==", custid));
    const snapshot = await getAggregateFromServer(q1, {
      paid: sum('paid')
    });

    const q2 = query(collection(db, 'orders'), where("custid", "==", custid));
    const snapshot1 = await getAggregateFromServer(q2, {
      totalamount: sum('totalamount')
    });

    const g3 = query(collection(db, 'customeracchistory'), where("custid", "==", custid));
    const snapshot2 = await getAggregateFromServer(g3, {
      discount: sum('discount')
    });

    let remaining = parseFloat(snapshot1.data().totalamount) - parseFloat(snapshot2.data().discount) - parseFloat(snapshot.data().paid);
    setrevrem(remaining);


  }

  const addOrderItems = async (item, itemid, rate) => {
    try {
      if (!custid || !staffid) {
        Toast.fire({ icon: "error", title: 'Please Select The Customer Or Staff' });
        return;
      }
      if (!orderDocid) {
        const docRef = await addDoc(collection(db, 'orders'), {
          tableid: tableid,
          custid: custid,
          staffid: staffid,
          customer: customer,
          staff: staff,
          orderdate: orderdate,
          tablebillno: tablebillno,
          billno: billno,
          totalamount: totalamount,
          discount: discount,
          paid: paid,
          remaining: remaining,
          paytype: paytype,
          status: 'Booked',
          uid: userData.uid,
          created: Timestamp.now()
        });
        await addDoc(collection(db, 'orderitems'), {
          orderid: docRef.id,
          tableid: tableid,
          custid: custid,
          staffid: staffid,
          customer: customer,
          staff: staff,
          orderdate: orderdate,
          tablebillno: tablebillno,
          billno: billno,
          itemname: item.data.prodname + " " + item.unit,
          itemid: itemid,
          unitname: item.unit,
          quantity: 1,
          rate: rate,
          total: parseFloat(rate),
          uid: userData.uid,
          created: Timestamp.now()
        });

        // =============== Product Stock Update ================
        const docProdRef = doc(db, 'products', itemid);
        const docSnap = await getDoc(docProdRef);
        if (docSnap.exists()) {
          let newstock = parseFloat(docSnap.data().stock) - 1;
          await updateDoc(doc(db, 'products', itemid), {
            stock: newstock,
          });
        }
        // =============== Product Stock Update ================
        fetchOrderItems(tableid, tablebillno, docRef.id);
        setorderDocid(docRef.id);
      } else {
        await setDoc(doc(db, 'orders', orderDocid), {
          tableid: tableid,
          custid: custid,
          staffid: staffid,
          customer: customer,
          staff: staff,
          orderdate: orderdate,
          tablebillno: tablebillno,
          billno: billno,
          totalamount: totalamount,
          discount: discount,
          paid: paid,
          remaining: remaining,
          paytype: paytype,
          status: 'Booked',
          uid: userData.uid,
          created: Timestamp.now()
        });

        const coll1 = query(collection(db, "orderitems"), where("itemid", "==", itemid), where("orderid", "==", orderDocid));
        const snapshot1 = await getDocs(coll1);
        if (snapshot1.docs.length === 0) {
          await addDoc(collection(db, 'orderitems'), {
            orderid: orderDocid,
            tableid: tableid,
            custid: custid,
            staffid: staffid,
            customer: customer,
            staff: staff,
            orderdate: orderdate,
            tablebillno: tablebillno,
            billno: billno,
            itemname: item.data.prodname + " " + item.unit,
            itemid: itemid,
            unitname: item.unit,
            quantity: 1,
            rate: rate,
            total: parseFloat(rate),
            uid: userData.uid,
            created: Timestamp.now()
          });
        } else {
          let qty = parseFloat(snapshot1.docs[0].data().quantity) + 1;
          const updateData = {
            orderid: orderDocid,
            tableid: tableid,
            custid: custid,
            staffid: staffid,
            customer: customer,
            staff: staff,
            orderdate: orderdate,
            tablebillno: tablebillno,
            billno: billno,
            itemname: item.data.prodname + " " + item.unit,
            itemid: itemid,
            quantity: qty,
            rate: rate,
            total: parseFloat(qty) * parseFloat(rate),
            uid: userData.uid,
            created: Timestamp.now()
          };

          const q = query(collection(db, "orderitems"), where("itemid", "==", itemid), where("orderid", "==", orderDocid));
          const querySnapshot = await getDocs(q);
          const batch = writeBatch(db);
          querySnapshot.forEach((doc) => {
            batch.update(doc.ref, updateData);
          });
          await batch.commit();
        }

        // =============== Product Stock Update ================
        const docProdRef = doc(db, 'products', itemid);
        const docSnap = await getDoc(docProdRef);
        if (docSnap.exists()) {
          let newstock = parseFloat(docSnap.data().stock) - 1;
          await updateDoc(doc(db, 'products', itemid), {
            stock: newstock,
          });
        }
        // =============== Product Stock Update ================

        fetchOrderItems(tableid, tablebillno, orderDocid);
      }
      fetchData1();

    } catch (error) {
      console.log(error.message);
    }

  }

  const fetchOrderItems = async (tableid, tablebillno, orderid) => {
    const q = query(collection(db, "orderitems"), where("tableid", "==", tableid), where("tablebillno", "==", tablebillno),
      where("orderid", "==", orderid));
    onSnapshot(q, (querySnapshot) => {
      setorderitems(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })));
    });

    const q1 = query(collection(db, 'orderitems'), where("tableid", "==", tableid), where("tablebillno", "==", tablebillno),
      where("orderid", "==", orderid));
    const snapshot = await getAggregateFromServer(q1, {
      totalAmount: sum('total')
    });
    settotalamount(snapshot.data().totalAmount);
    finaltotal()
  }

  const deleteOrderItem = async (docid, itemid, quantity, tableid, tablebillno, orderid) => {
    // =============== Product Stock Update ================
    const docProdRef = doc(db, 'products', itemid);
    const docSnap = await getDoc(docProdRef);
    if (docSnap.exists()) {
      let newstock = parseFloat(docSnap.data().stock) + parseFloat(quantity);
      await updateDoc(doc(db, 'products', itemid), {
        stock: newstock,
      });
    }

    const taskDocRef = doc(db, 'orderitems', docid)
    await deleteDoc(taskDocRef);
    const q1 = query(collection(db, 'orderitems'), where("orderid", "==", orderid));
    onSnapshot(q1, async (querySnapshot) => {
      if (querySnapshot.size === 0) {
        const taskDoc2Ref = doc(db, 'orders', orderid)
        await deleteDoc(taskDoc2Ref);
        setorderDocid("")
      }
    });
    fetchData1()
    fetchOrderItems(tableid, tablebillno, orderid);
    // =============== Product Stock Update ================
  }

  const upOrderQty = async (type, docid, itemid, qty, tableid, tablebillno, orderid, rate) => {
    if (type === "Remove") {

      const docProdRef = doc(db, 'products', itemid);
      const docSnap = await getDoc(docProdRef);
      if (docSnap.exists()) {
        let newstock = parseFloat(docSnap.data().stock) + parseFloat(qty);
        await updateDoc(doc(db, 'products', itemid), {
          stock: newstock,
        });
      }


      const docProdRef1 = doc(db, 'orderitems', docid);
      const docSnap1 = await getDoc(docProdRef1);
      if (docSnap1.exists()) {
        let newstock = parseFloat(docSnap1.data().quantity) - parseFloat(qty);
        if (newstock < 1) {
          const taskDocRef = doc(db, 'orderitems', docid)
          await deleteDoc(taskDocRef);
          const taskDoc2Ref = doc(db, 'orders', orderid)
          await deleteDoc(taskDoc2Ref);
          setorderDocid("")
        } else {
          await updateDoc(doc(db, 'orderitems', docid), {
            custid: custid,
            customer: customer,
            quantity: newstock,
            total: parseFloat(newstock) * parseFloat(rate),
          });
        }
      }

      fetchData1()
      fetchOrderItems(tableid, tablebillno, orderid);
    }
    else {

      const docProdRef = doc(db, 'products', itemid);
      const docSnap = await getDoc(docProdRef);
      if (docSnap.exists()) {
        let newstock = parseFloat(docSnap.data().stock) - parseFloat(qty);
        await updateDoc(doc(db, 'products', itemid), {
          stock: newstock,
        });
      }


      const docProdRef1 = doc(db, 'orderitems', docid);
      const docSnap1 = await getDoc(docProdRef1);
      if (docSnap1.exists()) {
        let newstock = parseFloat(docSnap1.data().quantity) + parseFloat(qty);
        await updateDoc(doc(db, 'orderitems', docid), {
          quantity: newstock,
          total: parseFloat(newstock) * parseFloat(rate),
        });
      } else {
        const taskDocRef = doc(db, 'orderitems', docid)
        await deleteDoc(taskDocRef);
      }


      fetchData1()
      fetchOrderItems(tableid, tablebillno, orderid);
    }
  }


  const finaltotal = () => {
    let remainingnew = parseFloat(totalamount) - parseFloat(paid) - parseFloat(discount);
    console.log(totalamount);
    console.log(paid);
    console.log(discount);
    if (remainingnew < 0) {
      Toast.fire({ icon: "error", title: 'Remaining should not be less than 0' });
      setpaid("");
      setremaining(totalamount);
      return;
    }
    if (paid === '') {
      setremaining(totalamount);
    } else {
      setremaining(remainingnew);
    }
  }

  const handleSubmit = async () => {
    await addDoc(collection(db, 'customers'), {
      name: name,
      mobile: mobile,
      address: address,
      uid: userData.uid,
      created: Timestamp.now()
    });
    setname(""); setmobile(""); setaddress("");
    fetchData1()
    Toast.fire({
      icon: "success",
      title: 'Customer Added Successfully!!'
    });
  }

  const saveBill = async () => {
    let rpaid = parseFloat(paid);
    if (totalamount === "" || rpaid === "" || remaining === "") {
      console.log(totalamount, rpaid, remaining);
      Toast.fire({ icon: "error", title: 'Please check the  total amount , paid & Remaining value is correct' });
      return;
    }
    try {
      // --------------------- update status of purchase table --------------------
      await updateDoc(doc(db, 'orders', orderDocid), {
        status: 'Paid',
        custid: custid,
        staffid: staffid,
        customer: customer,
        staff: staff,
        billno: billno,
        paiddate: orderdate,
        totalamount: totalamount,
        discount: parseFloat(discount),
        paid: parseFloat(paid),
        remaining: remaining,
        paytype: paytype,
      });


      // --------------------- Add dealer account paid amount --------------------
      await addDoc(collection(db, 'customeracchistory'), {
        custid: custid,
        staffid: staffid,
        customer: customer,
        staff: staff,
        orderDocid: orderDocid,
        billno: billno,
        paiddate: orderdate,
        totalamount: totalamount,
        discount: parseFloat(discount),
        paid: parseFloat(paid),
        remaining: remaining,
        paytype: paytype,
        status: 'Active',
        note: 'Paid at the time bill',
        uid: userData.uid,
        created: Timestamp.now()
      });

      settotalamount("0"); setpaid("0"); setremaining("0"); setorderDocid(""); setcustid(""); setbillno("");
      setpaytype("Cash"); setorderitems([]);

      Toast.fire({ icon: "success", title: 'Order Successfully Paid' });

      window.location.href = "/table-orders";

    } catch (error) {
      Toast.fire({ icon: "error", title: 'Something was wrong please try again' });
      console.log(error.message)
    }
  }





  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <button className="btn btn-secondary " style={{ float: "right", marginTop: 4 }} >|| Table Orders Management ||</button><br /><br />
            <div className="card card-primary card-outline mt-1">
              <div className="card-body table-responsive p-3" >
                {
                  seatingCat.map((item1, index) =>
                    <div key={index} >
                      <h6 style={{ textTransform: "uppercase", marginLeft: 10 }}><b>{item1.data.catname}</b></h6>
                      <div className='row'>
                        {
                          seatings.map((item, index) =>
                            <>
                              {
                                item1.id === item.data.seatcatid ?
                                  <div key={index} className='col-sm-2'>
                                    {
                                      item.status === 'Booked' ?
                                        <div key={index} className='tablebox1'>
                                          <h6 className='tablename'><b>{item.data.seatname} </b></h6>
                                          {
                                            item.orders.map((item1, index) =>
                                              <button key={index} className='billbox' style={{ fontWeight: "700" }}
                                                onClick={() => addTableId1(item.id, item.data.seatname, item1.data.tablebillno,
                                                  item1.data.billno, item1.id, item1.data.custid, item1.data.customer, item1.data.staffid, item1.data.staff)} data-toggle="modal" data-target="#addorder-modal">
                                                {item1.data.customer.data.name}</button>
                                            )
                                          }
                                          <button className='billbox' onClick={() => addTableId(item.id, item.data.seatname)} data-toggle="modal" data-target="#addorder-modal"><i className='fa fa-plus'></i></button>
                                        </div> :
                                        <div key={index} className='tablebox'>
                                          <h6 className='tablename'><b>{item.data.seatname}</b></h6>
                                          <button className='billbox' onClick={() => addTableId(item.id, item.data.seatname)} data-toggle="modal" data-target="#addorder-modal"><i className='fa fa-plus'></i></button>
                                        </div>
                                    }
                                  </div> : null
                              }
                            </>
                          )
                        }
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="modal fade" id="addorder-modal">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: "orangered" }}>
              <h4 className="modal-title" style={{ color: "white" }}>{tablename} Table</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body p-1">
              <div className='row'>
                <div className='col-sm-2'>
                  <div className='card' style={{ height: "100%", backgroundColor: "#F9F7F7" }}>
                    <div className='card-body'>
                      {
                        productCat.map((item, index) =>
                          <button key={index} onClick={() => fetchProducts(item.id)} style={{ textTransform: "uppercase", textAlign: "left" }} className='form-control btn btn-info mt-1'>{item.data.catname}</button>
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='card' style={{ height: "100%", backgroundColor: "#F9F7F7" }}>
                    <div className='card-body'>

                      <Select options={products} value={item} onChange={handleSelectItem} /><hr />
                      <div className='row'>
                        {
                          products.map((item, index) =>
                            <div key={index} className='col-sm-3'>
                              <button onClick={() => addOrderItems(item, item.id, item.data.rate)} style={{ fontSize: 12, padding: 0 }} className='form-control btn btn-secondary mt-1'> {item.data.prodname} {item.unit}</button>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-4'>
                  <div className='card' style={{ height: "100%" }}>
                    <div className='card-body'>
                      <button className="btn btn-info btn-xs" style={{ float: "right" }} data-toggle="modal" data-target="#customer-modal">Add New Customer</button><br />
                      <div className='row mt-2'>
                        <div className='col-sm-6'>
                          <Select options={customers} value={customer} onChange={handleSelectItem1} />
                          <label>Previous Outstanding Amount : <span style={{ color: "red" }}> Rs.{revrem}/- </span></label>
                        </div>
                        <div className='col-sm-6'>
                          <Select options={staffs} value={staff} onChange={handleSelectItem2} />
                        </div>
                      </div><hr />
                      <div className='row'>
                        <div className='col-sm-6'>
                          <label>Bill No : {billno}</label>
                        </div>
                        <div className='col-sm-6'>
                          <label style={{ float: "right" }}>Date No : {moment(orderdate).format('DD/MM/YYYY')}</label>
                        </div>
                      </div>
                      <div className='productBox '>
                        <table className='table'>
                          {
                            orderitems.map((item, index) =>
                              <tr key={index}>
                                <td>{item.data.itemname}  </td>
                                <td><button onClick={() => upOrderQty('Remove', item.id, item.data.itemid, 1, item.data.tableid, item.data.tablebillno, item.data.orderid, item.data.rate)} className='btn btn-xs btn-info'><i className='fa fa-minus'></i></button></td>
                                <td>{item.data.quantity}</td>
                                <td><button onClick={() => upOrderQty('Add', item.id, item.data.itemid, 1, item.data.tableid, item.data.tablebillno, item.data.orderid, item.data.rate)} className='btn btn-xs btn-info'><i className='fa fa-plus'></i></button></td>
                                <td>{item.data.total}/-</td>
                                <td><button onClick={() => deleteOrderItem(item.id, item.data.itemid, item.data.quantity, item.data.tableid, item.data.tablebillno, item.data.orderid, item.data.rate)} className='btn btn-xs btn-danger'><i className='fa fa-trash'></i></button></td>
                              </tr>
                            )
                          }
                        </table>
                      </div><hr />
                      <div className='col-sm-12'>
                        <h5 style={{ marginBottom: 20 }}>Total Amount :  <span style={{ float: "right" }}>Rs. {totalamount}/-</span> </h5>
                      </div><hr />
                      <div className='row'>
                        <div className='col-sm-4'>
                          <button onClick={handlePrint} className='form-control btn btn-info'>Print  <i className='fa fa-print'></i> </button>
                        </div>
                        <div className='col-sm-4'>
                          <button onClick={() => finaltotal()} data-toggle="modal" data-target="#bill-modal" className='form-control btn btn-success'>Create Bill</button>
                        </div>
                        <div className='col-sm-4'>
                          <button className='form-control btn btn-warning' data-dismiss="modal" aria-label="Close">New Order  </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="modal fade" id="bill-modal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Create Bill {tablename}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#customer-modal">Add New Customer</button><hr />
              <div className='row '>
                <div className='col-sm-4'>
                  <label>Select Customer Id  </label>
                  <select className='form-control' style={{ height: 35, fontSize: 14 }} value={custid} onChange={(e) => setcustid(e.target.value)}>
                    <option></option>
                    {customers.map((item) => (
                      <option value={item.id} key={item.id}>{item.data.name}</option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-4">
                  <label>Bill No </label>
                  <input type="text" className="form-control" style={{ height: 35, fontSize: 14 }} value={billno} onChange={(e) => setbillno(e.target.value)} />
                </div>
                <div className="col-sm-4">
                  <label>Bill Date </label>
                  <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={orderdate} onChange={(e) => setorderdate(e.target.value)} />
                </div>
              </div><br />
              <table className='table table-bordered'>
                <tr>
                  <th>Item Name</th>
                  <th>Quantity</th>
                  <th>Rate</th>
                  <th>Total</th>
                </tr>
                {
                  orderitems.map((item, index) =>
                    <tr key={index}>
                      <td>{item.data.itemname}  </td>
                      <td>{item.data.quantity}</td>
                      <td>{item.data.rate}</td>
                      <td>{item.data.total}/-</td>
                    </tr>
                  )
                }
              </table>
              <div className='row '>
                <div className='col-sm-3'>
                  <label>Grand Total </label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} readOnly value={totalamount} />
                </div>
                <div className="col-sm-3">
                  <label>Discount</label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} onKeyUp={() => finaltotal()} value={discount} onChange={(e) => setdiscount(e.target.value)} placeholder='Enter Paid Amount' />
                </div>
                <div className="col-sm-3">
                  <label>Paid</label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} onKeyUp={() => finaltotal()} value={paid} onChange={(e) => setpaid(e.target.value)} placeholder='Enter Paid Amount' />
                </div>
                <div className="col-sm-3">
                  <label>Payment Type</label>
                  <select className='form-control' style={{ height: 35, fontSize: 14 }} value={paytype} onChange={(e) => setpaytype(e.target.value)}>
                    <option value="Cash">Cash</option>
                    <option value="Bank">Bank</option>
                    <option value="RTGS">RTGS</option>
                    <option value="Online Pay">Online Pay</option>
                  </select>
                </div>
                <div className="col-sm-3">
                  <label>Remaining</label>
                  <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} value={remaining} readOnly />
                </div>
              </div><br></br>
              <center>
                <button onClick={() => saveBill()} className='btn btn-info'>Save Bill</button>
              </center>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="customer-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Customer</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6">
                  <label>  Customer</label>
                  <input type="text" value={name} onChange={(e) => setname(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6">
                  <label>Mobile</label>
                  <input type="text" value={mobile} onChange={(e) => setmobile(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>Address</label>
                  <input type="text" value={address} onChange={(e) => setaddress(e.target.value)} className="form-control" />
                </div>
              </div><br />
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => handleSubmit()} className="btn btn-primary" data-dismiss="modal">Save </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="bill-modal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Print Bill {tablename}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              {/* ==================================== Print Bill ==================================== */}

              <div style={{ padding: 20 }} ref={printRef}>
                <center>
                  <h6>108 Restaurent</h6>
                  <span>Ghatshil Road Road, Tuljapur, 413501</span><br />
                  {/* <span>Mobile No: 9921060146</span> */}
                </center>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label>Bill No : {billno}</label>
                  </div>
                  <div className='col-sm-6'>
                    <label style={{ float: "right" }}>Date No : {moment(orderdate).format('DD/MM/YYYY')}</label>
                  </div>
                </div><hr />
                <table width="100%">
                  <tr>
                    <th>Item Name</th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th>Total</th>
                  </tr>
                  {
                    orderitems.map((item, index) =>
                      <tr key={index}>
                        <td>{item.data.itemname}  </td>
                        <td>{item.data.quantity}</td>
                        <td>{item.data.rate}</td>
                        <td>{item.data.total}/-</td>
                      </tr>
                    )
                  }
                </table>
                <hr />
                <div>
                  <h6 style={{ marginBottom: 20 }}>Total Amount :  <span style={{ float: "right" }}>Rs. {totalamount}/-</span> </h6>
                </div>
              </div>



            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default TableOrders
