import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import moment from 'moment';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, limit } from 'firebase/firestore'
import { useReactToPrint } from 'react-to-print';

export default function ViewOrders() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();
    const [searchText, setsearchText] = useState("");
    const [orders, setorders] = useState([]);
    const [purchaseItesms, setpurchaseItesms] = useState([]);
    const [orderdate, setorderdate] = useState("");
    const [billno, setbillno] = useState("");
    const [totalamount, settotalamount] = useState(0);
    const [subtotal, setsubtotal] = useState(0);
    const [gstamount, setgstamount] = useState(0);
    const [orderitems, setorderitems] = useState([]);

    const printRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    })

    useEffect(() => {
        if (searchText === '') {
            fetchData1()
        } else {
            fetchData2()
        }
    }, [searchText]);



    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });

    const fetchData1 = async () => {
        try {
            const q1 = query(collection(db, 'orders'), orderBy("created", "desc"), limit(200))
            onSnapshot(q1, async (querySnapshot1) => {

                const combinedData = [];
                let custname = "";
                let staffname = "";
                for (const doc1 of querySnapshot1.docs) {
                    if (doc1.data().custid) {
                        const docRef = doc(db, "customers", doc1.data().custid);
                        const docSnap = await getDoc(docRef);
                        custname = docSnap.data().name;
                    }
                    else {
                        custname = "";
                    }

                    if (doc1.data().staffid) {
                        staffname = doc1.data().staff.label;
                    }
                    else {
                        staffname = "";
                    }
                    combinedData.push({
                        id: doc1.id,
                        data: doc1.data(),
                        custname: custname,
                        staffname: staffname,
                    });
                }
                setorders(combinedData);
            });



        } catch (error) {
            console.log(error.message);
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const fetchData2 = async () => {
        try {
            if (orders) {
                const filteredData = orders.filter((item) =>
                    item.custname.toUpperCase().includes(searchText.toUpperCase())
                );
                setorders(filteredData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const handleView = (docid) => {
        const q = query(collection(db, 'orderitems'), where("orderid", "==", docid));
        onSnapshot(q, (querySnapshot2) => {
            setpurchaseItesms(querySnapshot2.docs.map(doc => ({
                id: doc.id,
                data: doc.data(),
            })));
        });
    }

    const handleView1 = (docid, orderdate, billno, totalamount, paid) => {
        const q = query(collection(db, 'orderitems'), where("orderid", "==", docid));
        onSnapshot(q, (querySnapshot2) => {
            setorderitems(querySnapshot2.docs.map(doc => ({
                id: doc.id,
                data: doc.data(),
            })));
        });

        setorderdate(orderdate);
        setbillno(billno);
        settotalamount(totalamount);

        let gstamount = parseFloat(totalamount) * 18 / 100;
        setgstamount(gstamount);
        setsubtotal(totalamount - gstamount);
    }




    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right" }} >|| View Orders ||</button>
                        <a className="btn btn-info btn-sm" href="table-orders">Add Orders</a><br /><br />
                        <div className="card card-primary card-outline ex1">
                            <div className=" p-1">
                                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                                    className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
                            </div>
                            <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Order Date</th>
                                            <th>Bill No</th>
                                            <th>Customer Name</th>
                                            <th>Grand Total </th>
                                            <th>Paid Amount</th>
                                            <th>Remaining</th>
                                            <th>Staff</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            orders.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{moment(item.data.orderdate).format('DD-MM-YYYY')}</td>
                                                    <td>{item.data.billno}</td>
                                                    <td>{item.custname}</td>
                                                    <td>{item.data.totalamount}</td>
                                                    <td>{item.data.paid}</td>
                                                    <td>{item.data.totalamount - item.data.paid}</td>
                                                    <td>{item.staffname}</td>
                                                    <td>
                                                        <a onClick={() => handleView(item.id)} className="btn btn-sm btn-success" href='#0' data-toggle="modal" data-target="#text-modal1"> <i className="fa fa-eye"></i></a>&nbsp;
                                                        <a onClick={() => handleView1(item.id, item.data.orderdate, item.data.billno, item.data.totalamount, item.data.paid)} className="btn btn-sm btn-info" href='#0' data-toggle="modal" data-target="#text-modal2"> <i className="fa fa-print"></i></a>&nbsp;
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">View Order Items</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <table className='customers' style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th><label>Item</label></th>
                                        <th><label>Rate</label></th>
                                        <th><label>Qty</label></th>
                                        <th><label>Total </label></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        purchaseItesms.map((item, index) =>
                                            <tr key={index}>
                                                <td>{item.data.itemname}</td>
                                                <td>{item.data.rate}</td>
                                                <td>{item.data.quantity}</td>
                                                <td>{item.data.total}</td>
                                            </tr>

                                        )}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="text-modal2">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Print Bill</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div style={{ padding: 20 }} ref={printRef}>
                                <center>
                                    <h6>MATOSHRI BAR</h6>
                                    <span>Dharashiv Road, Tuljapur, 413501</span><br />
                                    <span>Mobile No: 9921060146</span>
                                </center>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <label>Bill No : {billno}</label>
                                    </div>
                                    <div className='col-sm-6'>
                                        <label style={{ float: "right" }}>Date No : {moment(orderdate).format('DD/MM/YYYY')}</label>
                                    </div>
                                </div><hr />
                                <table width="100%">
                                    <tr>
                                        <th>Item Name</th>
                                        <th>Quantity</th>
                                        <th>Rate</th>
                                        <th>Total</th>
                                    </tr>
                                    {
                                        orderitems.map((item, index) =>
                                            <tr key={index}>
                                                <td>{item.data.itemname}  </td>
                                                <td>{item.data.quantity}</td>
                                                <td>{item.data.rate}</td>
                                                <td>{item.data.total}/-</td>
                                            </tr>
                                        )
                                    }
                                </table>
                                <hr />
                                <div>
                                    <h6 style={{ marginBottom: 3 }}>Sub Total :  <span style={{ float: "right" }}> {subtotal}/-</span> </h6>
                                    <h6 style={{ marginBottom: 3 }}>CGST Amount (9%) :  <span style={{ float: "right" }}> {gstamount / 2}/-</span> </h6>
                                    <h6 style={{ marginBottom: 3 }}>SGST Amount (9%) :  <span style={{ float: "right" }}> {gstamount / 2}/-</span> </h6><hr />
                                    <h6 style={{ marginBottom: 3 }}>Grand Total :  <span style={{ float: "right" }}> {totalamount}/-</span> </h6>
                                    <hr />
                                </div>
                                <center>
                                    <i>Thank You! Visit Again.</i><br />
                                    <i>Software by Bluevipers Technology. (8459432930)</i>
                                </center>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-success" onClick={() => handlePrint()}>Print</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
