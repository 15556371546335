import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { Timestamp, addDoc, collection, deleteDoc, doc, getDoc, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
let staffid;
function Calendar() {

  const param = useParams();
  staffid = param.id;
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  const [staffname, setstaffname] = useState("");
  const [totpresent, settotpresent] = useState("");
  const [totabsent, settotabsent] = useState("");
  const [monthpayment, setmonthpayment] = useState("");
  const [thismonthpay, setthismonthpay] = useState("");
  const [attds, setattds] = useState([]);
  const [atttype, setatttype] = useState('Present');
  const [load, setload] = useState(true);
  const [date, setdate] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    fetchData1()
  }, []);

  const fetchData1 = async () => {
    try {
      if (load) {
        const q1 = query(collection(db, 'attendence'), where("staffid", '==', staffid))
        onSnapshot(q1, async (querySnapshot1) => {
          const combinedData = [];
          for (const doc1 of querySnapshot1.docs) {
            combinedData.push({
              id: doc1.id,
              data: doc1.data(),
              date: doc1.data().date,
              title: doc1.data().atttype,

            });
          }
          setattds(combinedData);

          const docRef = doc(db, "staffs", staffid)
          const docSnap = await getDoc(docRef)
          setstaffname(docSnap.data().name);
          console.log(docSnap.data().monthpayment)
          setmonthpayment(docSnap.data().monthpayment)
          if (docSnap.data().monthpayment) {
            let perdayamount = parseFloat(docSnap.data().monthpayment) / parseFloat(moment().daysInMonth());
            setthismonthpay(perdayamount.toFixed());
          }

        });
        let month = moment().format('YYYYMM');

        const q2 = query(collection(db, 'attendence'), where("staffid", '==', staffid), where('month', '==', month),
          where('atttype', '==', 'Present'))
        onSnapshot(q2, async (querySnapshot2) => {
          settotpresent(querySnapshot2.size);
        });
        const q3 = query(collection(db, 'attendence'), where("staffid", '==', staffid), where('month', '==', month),
          where('atttype', '==', 'Absent'))
        onSnapshot(q3, async (querySnapshot3) => {
          settotabsent(querySnapshot3.size);
        });

        setload(false);
      }
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message
      });
    }
  }

  const handleDateClick = (arg) => {
    let datee = arg.dateStr;
    setdate(moment(datee).format('YYYY-MM-DD'));
    handleShow()
  }



  const handleSubmit = async () => {
    if (!staffid || !date || !atttype) {
      Toast.fire({ icon: "error", title: 'Please fill the required fields' });
      return;
    }
    try {
      var date1 = new Date(date);
      let aptdate = date1.setDate(date1.getDate());

      const q11 = query(collection(db, 'attendence'), where("staffid", "==", staffid),
        where("date", "==", moment(aptdate).format('YYYY-MM-DD')));
      onSnapshot(q11, async (querySnapshot) => {
        if (querySnapshot.docs.length === 0) {
          await addDoc(collection(db, 'attendence'), {
            staffid: staffid,
            date: moment(aptdate).format('YYYY-MM-DD'),
            atttype: atttype,
            month: moment(aptdate).format('YYYYMM'),
            created: Timestamp.now(),
          });
        }
        else {
          await setDoc(doc(db, "attendence", querySnapshot.docs[0].id), {
            staffid: staffid,
            date: moment(aptdate).format('YYYY-MM-DD'),
            atttype: atttype,
            month: moment(aptdate).format('YYYYMM'),
            created: Timestamp.now(),
          });
        }
      });

      Toast.fire({
        icon: "success",
        title: 'Attendence Added Successfully!!'
      });
      handleClose();
      setTimeout(function () {
        window.location.href = "/staff-attendence/" + staffid;
      }, 5000);


    } catch (error) {
      console.log(error.message);
    }
  }



  return (
    <div className="content-wrapper">
      <br />
      <section className="content">
        <div className="container-fluid">
          <button className="btn btn-secondary " style={{ float: "right" }} >|| Add Attendence ||</button><br /><br />
          <div className="card card-primary card-outline ">
            <div className="card-body table-responsive p-3" >
              <div className='row'>
                <div className='col-sm-2'>
                  <p>Staff Name :<h5>{staffname}</h5> </p>
                </div>
                <div className='col-sm-2'>
                  <p>Total Present :<h5 style={{ color: "green" }}>{totpresent} Days</h5>  </p>
                </div>
                <div className='col-sm-2'>
                  <p>Total Absent :<h5 style={{ color: "red" }}>{totabsent} Days</h5>  </p>
                </div>
                <div className='col-sm-2'>
                  <p>Month :<h5>{moment().format('MMM')}</h5> </p>
                </div>
                <div className='col-sm-2'>
                  <p>Monthly Payment :<h5>Rs.{monthpayment} /-</h5> </p>
                </div>
                <div className='col-sm-2'>
                  <p>{moment().format('MMM YYYY')} Payment :<h5>Rs.{thismonthpay * totpresent} /-</h5> </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-primary card-outline ">
            <div className="card-body table-responsive p-0" >
              <FullCalendar
                plugins={[bootstrapPlugin, dayGridPlugin, interactionPlugin]}
                initialView={"dayGridMonth"}
                themeSystem='bootstrap'
                headerToolbar={{
                  // start: "prev,next",
                  center: "",
                  end: "dayGridMonth",
                }}
                events={attds}
                timeZone='Asia/Kolkata'
                height={"80vh"}
                dateClick={handleDateClick}
                eventColor='gray'
                eventClick={handleDateClick}
                slotDuration='00:20:00'
                slotMinTime="09:00:00"
                slotMaxTime="21:00:00"
              />
            </div>
          </div>




          <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header >
              <Modal.Title>Add Attendence</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <section className="content">
                <div className="container-fluid">
                  <div className='row'>
                    <div className='col-sm-4'>
                      <b >Present/Absent</b>
                      <select className='form-control' value={atttype} onChange={(e) => setatttype(e.target.value)}>
                        <option value="Present">Present</option>
                        <option value="Absent">Absent</option>
                      </select>
                    </div>
                    <div className='col-sm-4'>
                      <br />
                      <button className='btn btn-success' onClick={() => handleSubmit()}>Submit</button>
                    </div>
                  </div>
                </div>
              </section>
            </Modal.Body>
            <Modal.Footer>
              <button className='btn btn-danger' onClick={handleClose}>Close</button>
            </Modal.Footer>
          </Modal>
        </div>
      </section>

    </div>
  )
}



export default Calendar
