import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAeovaWPyEzmrANCF1w3u86yg90AMmtUfo",
  authDomain: "resto-a9c73.firebaseapp.com",
  projectId: "resto-a9c73",
  storageBucket: "resto-a9c73.appspot.com",
  messagingSenderId: "88381728677",
  appId: "1:88381728677:web:e924f2f373025f07422a4c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth  = getAuth();
export const db  = getFirestore(app);
export default app;